<template>
  <div>
    <Frame title="Registros patronales" :loading="loading">
      <Grid
        :data="data"
        :columns="columns"
        :actions="actions"
        addText="Agregar registro patronal"
        :showAdd="$can('AddEmployerRegistration', 'STM')"
        @addNew="addNew"
      />
    </Frame>
    <Modal :active.sync="showModal">
      <EmployeeRegistrationForm
        :employeeRegistrationToEdit="employeeRegistrationToEdit"
        :readonly="readonly"
      />
    </Modal>
  </div>
</template>

<script>
import ApiClient from '../../../utils/ApiClient';
import EmployeeRegistrationForm from './EmployeeRegistrationForm.vue';

export default {
  name: 'EmployeeRegistrations',
  components: {
    EmployeeRegistrationForm,
  },
  data() {
    return {
      data: [],
      showModal: false,
      loading: false,
      employeeRegistrationToEdit: null,
      actions: [
        {
          text: 'Ver',
          callback: this.onView,
        },
      ],
      columns: [
        {
          title: 'Registro patronal',
          field: 'employeeRegistration',
          searchable: true,
        },
        {
          title: 'Tipo riesgo',
          field: 'riesgoPuesto',
        },
        {
          title: 'Código postal',
          field: 'codigoPostal',
        },
        {
          title: 'Razón social',
          field: 'legalName',
          searchable: true,
        },
      ],
      readonly: false,
    };
  },
  mounted() {
    if (this.$can('EditEmployerRegistration', 'STM'))
      this.actions.push({
        text: 'Editar',
        callback: this.onEdit,
      });
    this.getRegistrations();
    this.$root.$on('reloadData', () => {
      this.getRegistrations();
    });
  },
  methods: {
    getRegistrations() {
      this.loading = true;
      const apiClient = new ApiClient('employerRegistration', 'stamp');
      apiClient.Get('?query=a').then((data) => {
        this.data = data;
        this.loading = false;
      });
    },
    addNew() {
      this.readonly = false;
      this.employeeRegistrationToEdit = null;
      this.showModal = true;
    },
    onView(item) {
      this.readonly = true;
      this.employeeRegistrationToEdit = {
        EmployerRegistration: item.employeeRegistration,
        PKC_CodigoPostal: item.pkC_CodigoPostal,
        PKC_RiesgoPuesto: item.pkC_RiesgoPuesto,
        PKCompany: item.pkCompany,
        PKEmployerRegistration: item.pkEmployerRegistration,
      };
      this.showModal = true;
    },
    onEdit(item) {
      this.readonly = false;
      this.employeeRegistrationToEdit = {
        EmployerRegistration: item.employeeRegistration,
        PKC_CodigoPostal: item.pkC_CodigoPostal,
        PKC_RiesgoPuesto: item.pkC_RiesgoPuesto,
        PKCompany: item.pkCompany,
        PKEmployerRegistration: item.pkEmployerRegistration,
      };
      this.showModal = true;
    },
  },
};
</script>

<template>
  <div>
    <Frame title="Registros patronales" :titleAction="titleAction">
      <Title title="Datos generales" />
      <Layout>
        <Column size="6">
          <Field label="Registro patronal">
            <Input
              :required="true"
              :readonly="employeeRegistrationToEdit"
              name="EmployerRegistration"
              textTransform="uppercase"
              v-model="model.EmployerRegistration"
              rules="required"
            />
          </Field>
        </Column>
        <Column size="6">
          <Field label="Razón social">
            <ComboBox
              :required="true"
              :disabled="readOnly"
              name="PKCompany"
              modelEndpoint="employerregistration/catalogs"
              groupData="companyCatalog"
              :limitSearch="null"
              api="stamp"
              v-model="model.PKCompany"
              rules="required"
            />
          </Field>
        </Column>
        <Column size="6">
          <Field label="Tipo de riesgo">
            <ComboBox
              :required="true"
              :disabled="readOnly"
              name="PKC_RiesgoPuesto"
              modelEndpoint="employerregistration/catalogs"
              groupData="riesgoPuestoCatalog"
              :limitSearch="null"
              api="stamp"
              v-model="model.PKC_RiesgoPuesto"
              rules="required"
            />
          </Field>
        </Column>
        <Column size="6">
          <Field label="Código postal">
            <ComboBox
              :required="true"
              :disabled="readOnly"
              name="PKC_CodigoPostal"
              modelEndpoint="employerregistration/catalogs"
              groupData="codigoPostalCatalog"
              api="stamp"
              v-model="model.PKC_CodigoPostal"
              rules="required"
            />
          </Field>
        </Column>
      </Layout>
      <hr />
      <Layout>
        <Column align="right">
          <Button
            color="terciary-outlined"
            @click="readonly && !readOnly ? onCancelEdit() : $parent.close()"
          >
            {{ readOnly ? 'Salir' : 'Cancelar' }}
          </Button>
          <Button
            v-if="$can('EditEmployerRegistration', 'STM')"
            color="secondary"
            @click="readOnly ? onEdit() : onSave()"
          >
            {{ readOnly ? 'Editar' : employeeRegistrationToEdit ? 'Actualizar' : 'Guardar' }}
          </Button>
        </Column>
      </Layout>
    </Frame>
  </div>
</template>

<script>
export default {
  name: 'EmployeeRegistrationForm',
  props: {
    employeeRegistrationToEdit: Object,
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      titleAction: 'Agregar',
      model: {
        EmployerRegistration: null,
        PKC_CodigoPostal: null,
        PKC_RiesgoPuesto: null,
        PKCompany: null,
        // PKEmployerRegistration: null,
      },
      readOnly: this.readonly,
    };
  },
  mounted() {
    if (this.readonly) this.$root.$emit('changeModeFrame', 'view');

    if (this.employeeRegistrationToEdit) {
      this.titleAction = this.readonly ? 'Ver' : 'Editar';
      this.model = { ...this.employeeRegistrationToEdit };
    }
  },
  methods: {
    onSave() {
      this.save('employerRegistration', this.model, 'stamp', 'PKEmployerRegistration').then(
        (data) => {
          if (data) {
            this.$parent.close();
          }
        }
      );
    },
    onEdit() {
      this.readOnly = false;
      this.titleAction = 'Editar';

      this.$root.$emit('changeModeFrame', 'edit');
    },
    onCancelEdit() {
      this.readOnly = true;
      this.titleAction = 'Ver';

      this.model = JSON.parse(JSON.stringify(this.employeeRegistrationToEdit));
      this.$root.$emit('changeModeFrame', 'view');
    },
  },
};
</script>
